<template>
  <div
    id="toast"
    :style="`width: 100%; display: flex; justify-content: center; top:${topToast}px; position: fixed; z-index: 4 !important;`"
  >
    <div
      class="toast_body"
      :class="
        successType
          ? 'toast_body_success'
          : errorType
          ? 'toast_body_error'
          : warningType
          ? 'toast_body_warning'
          : infoType
          ? 'toast_body_info'
          : ''
      "
    >
      <div class="fix_toast_wrapper">
        <i aria-hidden="true" class="v-icon mdi mdi-check-circle-outline"> </i>
        <div class="fix_toast">
          <div v-html="message"></div>
        </div>
        <button
          type="button"
          :class="
            successType
              ? 'v-btn v-btn--icon v-btn--round v-size--small button_success'
              : errorType
              ? 'v-btn v-btn--icon v-btn--round v-size--small button_error'
              : warningType
              ? 'v-btn v-btn--icon v-btn--round v-size--small button_warning'
              : infoType
              ? 'v-btn v-btn--icon v-btn--round v-size--small button_info'
              : ''
          "
          id="idbtnClose"
          aria-label="Fechar"
          @click="close"
        >
          <span class="v-btn__content">
            <i id="button" aria-hidden="true" class="v-icon mdi mdi-close-circle"></i>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  props: ['message', 'typeToast', 'topToast'],

  data() {
    return {
      successType: false,
      errorType: false,
      warningType: false,
      infoType: false,
    };
  },

  methods: {
    close() {
      this.successType = false;
      this.errorType = false;
      this.warningType = false;
      this.infoType = false;
      this.$emit('closeToast');
    },
  },

  watch: {
    $route(to, from) {
      this.close();
    },
    typeToast: {
      immediate: true,
      handler(value) {
        if (value == 'success') {
          this.successType = true;
          this.errorType = false;
          this.warningType = false;
          this.infoType = false;
        }
        if (value == 'error') {
          this.successType = false;
          this.errorType = true;
          this.warningType = false;
          this.infoType = false;
        }
        if (value == 'warning') {
          this.successType = false;
          this.errorType = false;
          this.warningType = true;
          this.infoType = false;
        }
        if (value == 'info') {
          this.successType = false;
          this.errorType = false;
          this.warningType = false;
          this.infoType = true;
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>

.toast_body {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: 'Roboto', sans-serif;
  line-height: 150%;
  letter-spacing: 0.01em;

  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  padding: 16px;
  text-align: center;
}

.toast_body_error {
  color: #962d33 !important;
  border-color: #962d33 !important;
  background-color: #f2e1e2 !important;
  border: 1px solid #962d33;
}

.toast_body_success {
  color: #288569 !important;
  border-color: #288569 !important;
  background-color: #e1f2ed !important;
  border: 1px solid #288569;
  width: auto;
}

.toast_body_warning {
  color: #807726 !important;
  border-color: #807726 !important;
  background-color: #f2f1e1 !important;
  border: 1px solid #807726;
}

.toast_body_info {
  color: #2d5796 !important;
  border-color: #2d5796 !important;
  background-color: #e1e8f2 !important;
  border: 1px solid #2d5796;
}

.v-btn__content {
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  line-height: normal;
  position: relative;
  transition: inherit;
  transition-property: opacity;
  border-radius: 20px;
  width: 10px !important;
  height: 10px !important;
}

.button_success >>> .v-btn__content {
  background-color: #288569;
}

.button_error >>> .v-btn__content {
  background-color: #962d33;
}

.button_warning >>> .v-btn__content {
  background-color: #807726;
}

.button_info >>> .v-btn__content {
  background-color: #2d5796;
}

.button_success >>> .v-btn__content i, 
.button_info >>> .v-btn__content, 
.button_warning >>> .v-btn__content, 
.button_error >>> .v-btn__content {
  margin: 0;
}

.button_error, 
.button_warning, 
.button_info,
.button_success {
  color: white !important;
}

.fix_toast {
  flex: 1 1 auto;
  margin-left: 16px;
  margin-right: 16px;
}

.fix_toast_wrapper {
  align-items: center;
  border-radius: inherit;
  display: flex;
}
</style>
