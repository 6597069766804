var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      style: `width: 100%; display: flex; justify-content: center; top:${_vm.topToast}px; position: fixed; z-index: 4 !important;`,
      attrs: { id: "toast" },
    },
    [
      _c(
        "div",
        {
          staticClass: "toast_body",
          class: _vm.successType
            ? "toast_body_success"
            : _vm.errorType
            ? "toast_body_error"
            : _vm.warningType
            ? "toast_body_warning"
            : _vm.infoType
            ? "toast_body_info"
            : "",
        },
        [
          _c("div", { staticClass: "fix_toast_wrapper" }, [
            _c("i", {
              staticClass: "v-icon mdi mdi-check-circle-outline",
              attrs: { "aria-hidden": "true" },
            }),
            _c("div", { staticClass: "fix_toast" }, [
              _c("div", { domProps: { innerHTML: _vm._s(_vm.message) } }),
            ]),
            _c(
              "button",
              {
                class: _vm.successType
                  ? "v-btn v-btn--icon v-btn--round v-size--small button_success"
                  : _vm.errorType
                  ? "v-btn v-btn--icon v-btn--round v-size--small button_error"
                  : _vm.warningType
                  ? "v-btn v-btn--icon v-btn--round v-size--small button_warning"
                  : _vm.infoType
                  ? "v-btn v-btn--icon v-btn--round v-size--small button_info"
                  : "",
                attrs: {
                  type: "button",
                  id: "idbtnClose",
                  "aria-label": "Fechar",
                },
                on: { click: _vm.close },
              },
              [_vm._m(0)]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "v-btn__content" }, [
      _c("i", {
        staticClass: "v-icon mdi mdi-close-circle",
        attrs: { id: "button", "aria-hidden": "true" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }